import { DialogProps, Divider, useTheme } from "@mui/material"
import { ReactNode } from "react"
import type { Icon as FeatherIcon } from "react-feather"
import {
  Modal,
  ModalBasicActions,
  ModalBasicActionsProps,
  ModalContent,
  ModalContentText,
  ModalTitle,
} from "../Modal"

export interface CommonsDialogProps
  extends Omit<DialogProps, "title">,
    ModalBasicActionsProps {
  Icon?: FeatherIcon
  title: ReactNode
  open: boolean
  loading?: boolean
  children?: ReactNode
  width?: string
}

/**
 * Dialogs inform users about a task and can contain critical information, require decisions, or involve multiple tasks.
 */
export const Dialog = ({
  Icon,
  title,
  cancelText,
  cancelAction,
  validateText,
  validateAction,
  validateEnabled = true,
  width,
  children,
  ...dialogProps
}: CommonsDialogProps) => {
  const { palette, spacing } = useTheme()

  return (
    <Modal
      sx={{ width: width ?? "720px", alignItems: "center", gap: spacing(2) }}
      {...dialogProps}
    >
      {!!Icon && <Icon size="61px" color={palette.primary.light1} />}

      <ModalTitle>{title}</ModalTitle>

      <Divider sx={{ width: "30%" }} />

      <ModalContent sx={{ minWidth: "350px", my: 1, mx: "auto" }}>
        {typeof children === "string" ? (
          <ModalContentText>{children}</ModalContentText>
        ) : (
          children
        )}
      </ModalContent>

      <ModalBasicActions
        cancelText={cancelText}
        cancelAction={cancelAction}
        validateText={validateText}
        validateAction={validateAction}
        validateEnabled={validateEnabled}
      />
    </Modal>
  )
}
